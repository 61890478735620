/** @jsx jsx */
import { jsx, SxStyleProp } from "theme-ui";
import { AnchorHTMLAttributes, memo } from "react";
import { Link, GatsbyLinkProps } from "gatsby";

export interface ULinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    GatsbyLinkProps<{}> {
  /** Theme UI JSX pragma */
  sx?: SxStyleProp;
}

const ULinkComponent: React.FC<ULinkProps> = ({
  children,
  to,
  partiallyActive,
  sx,
  ...rest
}) => (
  <Link
    to={to}
    sx={{ ...sx }}
    partiallyActive={partiallyActive}
    {...(rest as AnchorHTMLAttributes<HTMLAnchorElement>)}
  >
    {children}
  </Link>
);

export const ULink = memo(ULinkComponent);
